.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  opacity: 1;
  visibility: visible;
  transition: ease-in-out .3s;
  width: 100%;
  display: flex;
  justify-content: center;

  //&__show {
  //  opacity: 1;
  //  visibility: visible;
  //  transition: ease-in-out .3s;
  //}

  &__container {
    i {
      font-size: 40px;
    }
  }
}
