@charset "UTF-8";
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 2px solid #DDDEDE;
  content: "";
}

.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
  margin: 0 15px;
}

.symptom-selector nz-option-container div .cdk-virtual-scroll-content-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.symptom-selector nz-option-container div .cdk-virtual-scroll-content-wrapper nz-option-item {
  flex: 1 1 151px;
}

.ant-avatar-string {
  position: static;
}

.ant-spin-dot-item {
  background: var(--aiomed-night-blue-color);
}

.custom-progress {
  transform: rotate(180deg) !important;
}

.ant-spin-blur::after {
  opacity: 0.8 !important;
  pointer-events: auto;
}

.aiomed-avatar {
  color: #A2AFC3;
  background-color: #ECEEF2;
}
.aiomed-avatar span {
  padding-left: 0 !important;
  text-transform: uppercase;
}

i[nz-icon] {
  color: #B3B5B5;
}
i[nz-icon][severity=critical] {
  color: #E7424A;
}
i[nz-icon][severity=major] {
  color: #f28c15;
}
i[nz-icon][severity=info] {
  color: #6BB795;
}

button.btn-def {
  box-shadow: inset 0 0 0 1px #AFB7C7 !important;
}
button.btn-def:hover {
  box-shadow: inset 0 0 0 1px #758198 !important;
}

.mob-container {
  padding: 0 16px 16px 16px;
  min-width: 360px;
  width: 100%;
  background: var(--aiomed-white-color);
}

.container {
  padding: 0 35px 0 56px;
  width: 100%;
  background: var(--aiomed-white-color);
}
@media (max-width: 1280px) {
  .container {
    padding: 0 0 0 22px;
  }
}

button.btn-prime {
  background: #263558 !important;
  color: var(--aiomed-white-color) !important;
}
button.btn-prime:hover {
  background: rgb(67, 83, 122) !important;
}

@keyframes redBlinker {
  to {
    background: #E7424A;
  }
}
@keyframes blueBlinker {
  to {
    background: #007FFF;
  }
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
}

/* width */
/* Track */
/* Handle */
/* Handle on hover */
.table-background {
  background: rgba(246, 246, 249, 0.4352941176) 0 0 no-repeat padding-box !important;
}

.skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 400% 100%; /* Увеличьте размер градиента для создания эффекта бегущей строки */
  animation: skeleton-pulse 2s infinite linear;
}

@keyframes skeleton-pulse {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
section.entities-table {
  padding: 19px 22px 10px 0;
  position: relative;
  min-height: calc(100vh - 200px);
  text-align: right;
  max-width: 100%;
}
section.entities-table nz-table {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
  padding-top: 10px;
}
section.entities-table nz-table ::-webkit-scrollbar {
  width: 5px;
}
section.entities-table nz-table ::-webkit-scrollbar-track {
  border-radius: 10px;
}
section.entities-table nz-table ::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 10px;
}
section.entities-table nz-table ::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}
section.entities-table nz-table thead tr th {
  padding: 8.5px 16px;
  text-align: left;
  font-weight: bold;
  color: #758198;
  font-size: 12px;
}
section.entities-table nz-table thead tr th svg {
  color: #758198;
}
section.entities-table nz-table thead tr th.item-name .ant-checkbox-wrapper {
  margin-right: 34px;
}
section.entities-table nz-table thead tr th.item-name .ant-checkbox-inner {
  border-radius: initial;
}
section.entities-table nz-table thead tr th.item-name .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #263558;
  border-color: #263558;
}
section.entities-table nz-table thead tr th.checkbox {
  padding-left: 10px;
}
section.entities-table nz-table tbody tr {
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  border-bottom: 1px solid #f4f4f8;
  position: relative;
}
section.entities-table nz-table tbody tr td.checkbox {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
section.entities-table nz-table tbody tr td.checkbox label, section.entities-table nz-table tbody tr td.checkbox div {
  z-index: 1;
}
section.entities-table nz-table tbody tr td.absolute {
  left: 0;
  position: absolute;
  width: 301px;
  opacity: 1;
}
section.entities-table nz-table tbody tr td {
  padding: 8px 0;
}
section.entities-table nz-table tbody tr td .ant-checkbox-inner {
  border-radius: initial;
}
section.entities-table nz-table tbody tr td .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #263558;
  border-color: #263558;
}
section.entities-table nz-table tbody tr td:first-child div {
  padding-left: 17px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  justify-content: left;
}
section.entities-table nz-table tbody tr td:last-child div {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
section.entities-table nz-table tbody tr td div.content {
  color: #252a36;
  font-weight: 400;
  font-size: 14px;
  height: 43px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  padding-left: 16px;
}
section.entities-table nz-table tbody tr td div.content i {
  font-size: 15px;
}
section.entities-table nz-table tbody tr td div.content i.empty-line {
  font-size: 24px;
}
section.entities-table nz-table tbody tr td div.content i.report-edit {
  font-size: 25px;
  margin-left: 5px;
  margin-bottom: 5px;
}
section.entities-table nz-table tbody tr td div.content i.manual {
  color: #252a36;
  font-size: 14px;
  margin-right: 3px;
}
section.entities-table nz-table tbody tr td div.content i.report-emr {
  font-size: 25px;
  margin-bottom: 2px;
  color: #263558;
}
section.entities-table nz-table tbody tr td div.content span.item-value {
  padding: 0 2px;
}
section.entities-table nz-table tbody tr td div.content span.temp {
  font-size: 12px;
  margin-top: 2px;
  margin-left: 2px;
}
section.entities-table nz-table tbody tr td div.content span.item-small {
  margin-top: 3px;
  margin-left: 2px;
  font-size: 9px;
}
section.entities-table nz-table tbody tr td div.content span.item-degree {
  margin-left: 2px;
  margin-bottom: 6px;
  font-size: 9px;
}
section.entities-table nz-table tbody tr td div.content ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
section.entities-table nz-table tbody tr td div.content ul.custom-symptoms li {
  height: 21px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 13px;
  margin: 0 8px 8px 0;
}
section.entities-table nz-table tbody tr td div.content ul.custom-symptoms li.symptom {
  border: 1px solid #778196;
  background: #FFFFFF 0 0 no-repeat padding-box;
}
section.entities-table nz-table tbody tr td div.content ul.custom-symptoms li.condition {
  border: 1px solid #778196;
  background: #758198 0 0 no-repeat padding-box;
  color: #FFFFFF;
}
section.entities-table nz-table tbody tr td div.content span.monitored {
  width: 10px;
  height: 10px;
  background: #5BE18E 0 0 no-repeat padding-box;
  border-radius: 50%;
}
section.entities-table nz-table tbody tr td div.content div.report-edit, section.entities-table nz-table tbody tr td div.content button.report-edit {
  width: 36px;
  height: 41px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  transition: ease-in-out 0.3ms;
  position: absolute;
}
section.entities-table nz-table tbody tr td div.content div.report-edit span.loading, section.entities-table nz-table tbody tr td div.content button.report-edit span.loading {
  position: absolute;
  font-size: 15px;
  top: 21px;
}
section.entities-table nz-table tbody tr td div.content div.report-edit:hover, section.entities-table nz-table tbody tr td div.content button.report-edit:hover {
  background: rgba(241, 242, 245, 0.537254902);
  transition: ease-in-out 0.3ms;
}
section.entities-table nz-table tbody tr td div.content button.report-edit {
  background: transparent;
  border: none;
}
section.entities-table nz-table tbody tr td div.content button.report-edit[disabled] i {
  cursor: not-allowed !important;
  color: rgb(229, 229, 229) !important;
}
section.entities-table nz-table tbody tr td div.content div.device-actions {
  width: 36px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 5px;
  border: none !important;
  margin-left: 4px;
  transition: ease-in-out 0.3s;
}
section.entities-table nz-table tbody tr td div.content div.device-actions:hover {
  transition: ease-in-out 0.3s;
  background: #F6F6F9 0 0 no-repeat padding-box;
}
section.entities-table nz-table tbody tr td div.content div.device-actions i {
  z-index: 1001;
  font-size: 17px;
}
section.entities-table nz-table tbody tr td div.content span.gray {
  color: #A2AFC3;
}
section.entities-table nz-table tbody tr td div.need-calibrate {
  color: #c6c6c9;
}
section.entities-table nz-table tbody tr td div.monitored {
  padding-left: 45px;
}
section.entities-table nz-table tbody tr td div.monitored .sensor-animation {
  width: 23px;
  height: 23px;
  margin-left: -6px;
}
section.entities-table nz-table tbody tr td div.white {
  background: #fff;
}
section.entities-table nz-table tbody tr td div.bold {
  font-weight: bold;
}
section.entities-table nz-table tbody tr td div.old-status {
  color: #B3B5B5 !important;
}
section.entities-table nz-table tbody tr td div.old-status i {
  color: #B3B5B5 !important;
  animation: none;
}
section.entities-table nz-table tbody tr td div.input-box:focus-within, section.entities-table nz-table tbody tr td div.bp-input-box:focus-within {
  border: 2px solid #263558;
}
section.entities-table nz-table tbody tr td div.input-box input, section.entities-table nz-table tbody tr td div.bp-input-box input {
  width: 78%;
  border: none;
  border-radius: 5px;
  outline: none;
  height: 28px;
  font: normal normal normal 14px/24px Lato;
  color: #262626;
}
section.entities-table nz-table tbody tr td div.input-box input::-webkit-outer-spin-button, section.entities-table nz-table tbody tr td div.input-box input::-webkit-inner-spin-button, section.entities-table nz-table tbody tr td div.bp-input-box input::-webkit-outer-spin-button, section.entities-table nz-table tbody tr td div.bp-input-box input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
section.entities-table nz-table tbody tr td div.input-box.red-value:focus-within, section.entities-table nz-table tbody tr td div.bp-input-box.red-value:focus-within {
  border: 2px solid #E7424A;
}
section.entities-table nz-table tbody tr td div.bp-input-box {
  position: relative;
}
section.entities-table nz-table tbody tr td div.bp-input-box input {
  width: 40%;
  padding-right: 7px;
}
section.entities-table nz-table tbody tr td div.bp-input-box span {
  position: absolute;
  left: 44%;
}
section.entities-table nz-table tbody tr td div.bp-input-box span.manual {
  position: absolute;
  left: 50%;
  transform: translateX(12px);
}
section.entities-table nz-table tbody tr td div.red-value, section.entities-table nz-table tbody tr td div.red-value-d {
  color: #E7424A !important;
}
section.entities-table nz-table tbody tr td div.red-value input, section.entities-table nz-table tbody tr td div.red-value-d input {
  color: red;
}
section.entities-table nz-table tbody tr td div.red-value i.manual, section.entities-table nz-table tbody tr td div.red-value-d i.manual {
  color: #E7424A !important;
}
section.entities-table nz-table tbody tr td div.table-name {
  justify-content: flex-start;
  align-items: center;
  padding-left: 17px;
}
section.entities-table nz-table tbody tr td div.table-name img {
  border-radius: 50%;
}
section.entities-table nz-table tbody tr td div.table-name span {
  padding-left: 14px;
}
section.entities-table nz-table tbody tr td div.batteryLevel i {
  font-size: 22px;
  margin-right: 3px;
}
section.entities-table nz-table tbody tr[severity=critical] td div {
  background: var(--table-red-color);
}
section.entities-table nz-table tbody tr[severity=major] td div {
  background: var(--table-yellow-color);
}

.ant-table-content {
  height: 555px;
}

.ant-table-tbody {
  position: relative !important;
  margin-top: 30px !important;
}

.ant-table-container .scroll-content {
  min-height: 400px !important;
}

.entities-table__title {
  display: flex;
  justify-content: flex-start;
  font: normal normal bold 16px/24px Lato;
  color: #252A36;
  text-transform: uppercase;
}
.entities-table__search-pagination-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 11px;
  margin-bottom: 6px;
}
.entities-table__search-pagination-container .ant-input-affix-wrapper {
  border: 1px solid #DBDCDD;
  border-radius: 11px;
  padding: 0 11px;
  height: 28px;
  width: 336px;
}
.entities-table__search-pagination-container ul.device-filters {
  list-style: none;
  margin: 0 0 0 18px;
  padding: 0;
  display: flex;
}
.entities-table__search-pagination-container ul.device-filters li {
  height: 32px;
  border-radius: 16px;
  background: #DDDEDE;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 18px;
  margin-right: 8px;
  font: normal normal normal 14px/20px Lato;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.8705882353);
  cursor: pointer;
  transition: ease-in-out 0.3s;
}
.entities-table__search-pagination-container ul.device-filters li.active {
  background: #263558;
  color: rgba(255, 255, 255, 0.8705882353);
}
.entities-table__search-pagination-container ul.device-filters.report-filter li {
  width: 107px;
  padding: 0;
}
.entities-table__search-pagination-container button.assign-device {
  position: absolute;
  right: 57px;
  border: 0.5px solid #263558;
  border-radius: 4px;
  background: transparent;
  padding: 0;
  outline: none;
  font: normal normal normal 12px/24px Lato;
  color: #263558;
  transition: ease-in-out 0.3s;
  width: 132px;
  height: 32px;
  cursor: pointer;
}
.entities-table__search-pagination-container button.assign-device:hover {
  transition: ease-in-out 0.3s;
  background: #263558 0 0 no-repeat padding-box;
  border-radius: 4px;
  color: #FFFFFF;
}
.entities-table__tab-container {
  margin: 14px 0 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  position: relative;
  width: 98.6%;
  border-bottom: 1px solid #DDDEDE;
}
.entities-table__tab-container li {
  padding: 0 23px 12px 23px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: ease-in-out 0.1s;
  font: normal normal medium 14px/24px Lato;
  color: #758198;
  position: relative;
}
.entities-table__tab-container li.active {
  border-bottom: 2px solid #263558;
  transition: ease-in-out 0.1s;
  font: normal normal bold 14px/24px Lato;
  color: #263558;
}
.entities-table__tab-container li span {
  position: absolute;
  top: -5px;
  right: -4px;
  width: 22px;
  height: 22px;
  background: #E8EFFA 0 0 no-repeat padding-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #758198;
}
.entities-table__filters {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}
.entities-table__filters li {
  cursor: pointer;
  width: 56px;
  height: 37.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF 0 0 no-repeat padding-box;
  border: 1px solid #E8E8E9;
  border-radius: 6px;
  font: normal normal medium 16px/48px Lato;
  color: #758198;
  margin-left: 8px;
  transition: ease-in-out 0.2s;
}
.entities-table__filters li.active {
  border: 1px solid #263558;
  transition: ease-in-out 0.2s;
  color: #263558;
}
.entities-table button.import-pcc {
  position: absolute;
  right: 57px;
  border: 0.5px solid #263558;
  height: 32px;
  top: -39px;
  border-radius: 4px;
  font: normal normal normal 12px/24px Lato;
  color: #263558;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  background: #fff;
}
.entities-table button.import-pcc:hover {
  transition: ease-in-out 0.3s;
  background: #263558 0 0 no-repeat padding-box;
  color: #FFFFFF;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  opacity: 1;
  visibility: visible;
  transition: ease-in-out 0.3s;
  width: 100%;
  display: flex;
  justify-content: center;
}
.loading__container i {
  font-size: 40px;
}

section.aiomed-sider {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 21px;
}
section.aiomed-sider ul.aiomed-sider-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.aiomed-sider ul.aiomed-sider-menu li {
  width: 64px;
  height: 56px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-bottom: 21px;
  transition: ease-in-out 0.1s;
}
section.aiomed-sider ul.aiomed-sider-menu li.active {
  background: #535D77 0 0 no-repeat padding-box;
  border-radius: 18px;
  transition: ease-in-out 0.1s;
}
section.aiomed-sider ul.aiomed-sider-menu li.active svg {
  fill: white;
  color: white;
}
section.aiomed-sider ul.aiomed-sider-menu li.active .default-insights {
  display: none;
}
section.aiomed-sider ul.aiomed-sider-menu li.active .active-insights {
  display: inline-block;
}
section.aiomed-sider ul.aiomed-sider-menu li .active-insights {
  display: none;
}
section.aiomed-sider ul.aiomed-sider-menu li svg {
  font-size: 45px;
  fill: #A8AEBC;
  color: #A8AEBC;
}
section.aiomed-sider ul.aiomed-sider-menu li .ant-badge-count, section.aiomed-sider ul.aiomed-sider-menu li .ant-badge-dot, section.aiomed-sider ul.aiomed-sider-menu li .ant-badge .ant-scroll-number-custom-component {
  top: 8px;
}
section.aiomed-sider ul.aiomed-sider-menu li .ant-badge-count {
  width: 16px;
  height: 16px;
  padding: 0;
  font-size: 8px;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}
section.aiomed-sider ul.aiomed-sider-menu li:hover {
  background: #535D77 0 0 no-repeat padding-box;
  border-radius: 18px;
  transition: ease-in-out 0.1s;
}
section.aiomed-sider ul.aiomed-sider-menu li:hover svg {
  fill: white;
  color: white;
}
section.aiomed-sider ul.aiomed-sider-menu li.shift-planer {
  position: relative;
}
section.aiomed-sider ul.aiomed-sider-menu li.shift-planer:hover .shift-planer-text {
  color: #fff;
}
section.aiomed-sider ul.aiomed-sider-menu li.shift-planer svg {
  font-size: 29px;
  margin-bottom: 21px;
}
section.aiomed-sider ul.aiomed-sider-menu li.shift-planer div.shift-planer-text {
  font: normal normal normal 8px/24px Lato;
  color: #A8AEBC;
  position: absolute;
  bottom: 0;
}
section.aiomed-sider ul.aiomed-sider-menu li.shift-planer.active div.shift-planer-text {
  color: #fff;
}
section.aiomed-sider ul.aiomed-sider-menu li.shift-planer:hover div.shift-planer-text {
  color: #fff;
}
section.aiomed-sider ul.aiomed-sider-menu li.insights:hover .default-insights {
  display: none;
}
section.aiomed-sider ul.aiomed-sider-menu li.insights:hover .active-insights {
  display: inline-block;
}
section.aiomed-sider div.aiomed-sider-menu-user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
section.aiomed-sider div.aiomed-sider-menu-user img, section.aiomed-sider div.aiomed-sider-menu-user span {
  width: 25px;
  height: 25px;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  display: flex;
  font-size: 8px;
  justify-content: center;
  align-items: center;
  background: #ccc;
}
section.aiomed-sider div.aiomed-sider-menu-user div {
  font: normal normal normal 12px/24px Lato;
  color: #FFFFFF;
}

.menu {
  position: fixed;
  width: 260px;
  transition: ease-in-out 0.1s;
}

.menu-collapsed {
  width: 96px;
  transition: ease-in-out 0.1s;
}

.overdue-task-list-actions-dropdown {
  top: -26px;
  left: -35px;
}
.overdue-task-list-actions-dropdown ul {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1490196078);
  border: 1px solid #DBDCDD;
  border-radius: 4px;
  padding: 0;
}
.overdue-task-list-actions-dropdown ul li {
  width: 171px;
  height: 39px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  font: normal normal normal 16px/24px Lato;
  color: #252A36;
  cursor: pointer;
  border-bottom: 1px solid #DBDCDD;
}
.overdue-task-list-actions-dropdown ul li:last-child {
  border-bottom: 1px solid transparent;
}
.overdue-task-list-actions-dropdown ul li:hover {
  background: #F1F2F5 0 0 no-repeat padding-box;
}