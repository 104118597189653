.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 2px solid #DDDEDE;
  content: '';
}

.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
  margin: 0 15px;
}

.symptom-selector {
  nz-option-container {
    div {
      .cdk-virtual-scroll-content-wrapper {
        display: flex;
        flex-wrap: wrap;

        nz-option-item {
          flex: 1 1 151px;
        }
      }
    }
  }
}

.ant-avatar-string {
  position: static;
}


//.scroll-content {
//  display: flow-root;
//  -webkit-transform: translate3d(0, 0, 0);
//  transform: translate3d(0, 0, 0);
//  //background: red !important;
//}
//
//.scrollbar-track {
//  position: absolute !important;
//  opacity: 1 !important;
//  //z-index: 1 !important;
//  background: white !important;
//  -webkit-user-select: none !important;
//  -moz-user-select: none !important;
//  -ms-user-select: none !important;
//  user-select: none !important;
//  -webkit-transition: opacity 0.5s 0.5s ease-out !important;
//  transition: opacity 0.5s 0.5s ease-out !important;
//  z-index: 100000 !important;
//}

//.scrollbar-track.show,
//.scrollbar-track:hover {
//  opacity: 1;
//  -webkit-transition-delay: 0s;
//  transition-delay: 0s;
//}

//.scrollbar-track-x {
//  bottom: 0;
//  left: 0;
//  width: 100%;
//  height: 8px;
//}
//
//.scrollbar-track-y {
//  top: 0;
//  right: 0;
//  width: 8px;
//  height: 100%;
//}
//
//.scrollbar-thumb {
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 8px;
//  height: 8px;
//  background: gray;
//  border-radius: 4px;
//}


.ant-spin-dot-item {
  background: var(--aiomed-night-blue-color);
}

.custom-progress {
  transform: rotate(180deg) !important;
}

.ant-spin-blur::after {
  opacity: 0.80 !important;
  pointer-events: auto;
}

.aiomed-avatar {
  color: #A2AFC3;
  background-color: #ECEEF2;

  span {
    padding-left: 0 !important;
    text-transform: uppercase;
  }
}

i[nz-icon] {
  color: #B3B5B5;

  &[severity="critical"] {
    color: #E7424A;
    //animation: blinker 1.7s cubic-bezier(.5, 0, 1, 1) infinite alternate;
  }

  &[severity="major"] {
    color: #f28c15;
  }

  &[severity="info"] {
    color: #6BB795;
  }
}

button.btn-def {
  box-shadow: inset 0 0 0 1px #AFB7C7 !important;

  &:hover {
    box-shadow: inset 0 0 0 1px #758198 !important;
  }
}

.mob-container {
  padding: 0 16px 16px 16px;
  min-width: 360px;
  width: 100%;
  //height: 100vh;
  background: var(--aiomed-white-color);
}

.container {
  padding: 0 35px 0 56px;
  width: 100%;
  //height: 100vh;
  background: var(--aiomed-white-color);

  @media (max-width: 1280px) {
    padding: 0 0 0 22px;
  }
}

button.btn-prime {
  background: #263558 !important;
  color: var(--aiomed-white-color) !important;

  &:hover {
    background: rgba(67, 83, 122, 1) !important;
  }
}

@keyframes redBlinker {
  to {
    background: #E7424A;
  }
}

@keyframes blueBlinker {
  to {
    background: #007FFF;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

//#cdk-overlay-1 {
//  top: 40px !important;
//}

/* width */
//::-webkit-scrollbar {
//  width: 0; /* Remove scrollbar space */
//  background: transparent; /* Optional: just make scrollbar invisible */
//}

/* Track */
//::-webkit-scrollbar-track {
//  background: #fff;
//}

/* Handle */
//::-webkit-scrollbar-thumb {
//  background: #FF0000;
//  height: 5px;
//  height: 5px;
//}
//::-webkit-scrollbar {
//  width: 5px;
//}
//
//::-webkit-scrollbar-track {
//  background-color: transparent;
//  border-radius: 100px;
//}
//
//::-webkit-scrollbar-thumb {
//  background-color: gray;
//}

//
//::-webkit-scrollbar-track:hover {
//  ::-webkit-scrollbar-thumb {
//    background: #88dd88;
//    transition: ease-in-out .3s;
//  }
//}

/* Handle on hover */
//::-webkit-scrollbar-thumb:hover {
//  background: #88dd88;
//  transition: ease-in-out .3s;
//}

.table-background {
  background: #F6F6F96F 0 0 no-repeat padding-box !important;
}

.skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 400% 100%; /* Увеличьте размер градиента для создания эффекта бегущей строки */
  animation: skeleton-pulse 2s infinite linear;
}

@keyframes skeleton-pulse {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
